import revive_payload_client_4sVQNw7RlN from "/home/forge/pos-pre.terradev.app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/forge/pos-pre.terradev.app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/forge/pos-pre.terradev.app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/forge/pos-pre.terradev.app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/home/forge/pos-pre.terradev.app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/forge/pos-pre.terradev.app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/home/forge/pos-pre.terradev.app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_A0OWXRrUgq from "/home/forge/pos-pre.terradev.app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/forge/pos-pre.terradev.app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/forge/pos-pre.terradev.app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_OVoKJro5pc from "/home/forge/pos-pre.terradev.app/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import sentry_client_shVUlIjFLk from "/home/forge/pos-pre.terradev.app/plugins/sentry.client.ts";
import autoAnimate_4HQGapz9xs from "/home/forge/pos-pre.terradev.app/plugins/autoAnimate.ts";
import dayjs_pGiXRjcsJO from "/home/forge/pos-pre.terradev.app/plugins/dayjs.ts";
import dialogHelper_rsjkwpiVTZ from "/home/forge/pos-pre.terradev.app/plugins/dialogHelper.ts";
import fontawesome_klhsrycjcK from "/home/forge/pos-pre.terradev.app/plugins/fontawesome.js";
import gsap_client_Z6XKCRtOCT from "/home/forge/pos-pre.terradev.app/plugins/gsap.client.js";
import maska_UHaKf2z1iQ from "/home/forge/pos-pre.terradev.app/plugins/maska.ts";
import numeral_kn0SDOMk58 from "/home/forge/pos-pre.terradev.app/plugins/numeral.js";
import printer_client_XJcxXalac2 from "/home/forge/pos-pre.terradev.app/plugins/printer.client.ts";
import pusherChannels_UGpE1wCums from "/home/forge/pos-pre.terradev.app/plugins/pusherChannels.js";
import qrCode_BzXNNCAjM0 from "/home/forge/pos-pre.terradev.app/plugins/qrCode.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_OVoKJro5pc,
  sentry_client_shVUlIjFLk,
  autoAnimate_4HQGapz9xs,
  dayjs_pGiXRjcsJO,
  dialogHelper_rsjkwpiVTZ,
  fontawesome_klhsrycjcK,
  gsap_client_Z6XKCRtOCT,
  maska_UHaKf2z1iQ,
  numeral_kn0SDOMk58,
  printer_client_XJcxXalac2,
  pusherChannels_UGpE1wCums,
  qrCode_BzXNNCAjM0
]